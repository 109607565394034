"use client";

import Image from "next/image";
import { routes } from "@/lib/utils/routes";
import { useTranslation } from "@/i18n/client";
import Pnf from "@/assets/static/404-graphic.svg?url";
import Button from "@/components/common/shared/Button/button";

export const metadata = {
  title: "404: This page could not be found.",
};

const NotFound = () => {
  const { t } = useTranslation(["not-found"]);
  return (
    <section className="register">
      <div className="container">
        <div className="row">
          <div className="col w-12/12">
            <div className="pnf-box">
              <Image
                width={200}
                height={200}
                src={Pnf}
                alt="404 Error"
                className="xs:w-[469px] w-[260px] xs:mb-[40px] mb-[24px]"
              />
              <h4 className="lg:text-headline-2 xs:text-headline-4 text-subtitle-2-b xs:mb-4 mb-2 text-primary-black">
                {t("heading")}
              </h4>
              <p className="xs:text-body-text-1 text-body-text-3 text-primary-black-800 text-center xs:mb-8 mb-6">
                {t("description")}
              </p>
              <Button
                prefetch={false}
                type="link"
                href={routes.home}
                theme="blue-btn"
                className="max-w-[194px]"
                icon="arrow"
                iconPosition="left"
              >
                {t("home")}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NotFound;
